import { useCallback, useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";

import { NumberField } from "@new-black/lyra";
import { isNil } from "lodash";

import { DecimalIcon, NumberIcon } from "assets/icons/components/custom-field-icons";
import NumberInput from "components/suite-ui/number-input";

import { ICustomFieldConsumerProps } from "../custom-field-consumer.types";

interface ICustomNumberFieldConsumerProps extends ICustomFieldConsumerProps<number | undefined> {
  min?: number;
  max?: number;
  float?: boolean;
  minMessage?: string;
  maxMessage?: string;
  outOfRangeMessage?: string;
}

interface ICustomNumberFieldValidatorOptions {
  min?: number;
  max?: number;
  required?: boolean;
  requiredMessage?: string;
  minMessage?: string;
  maxMessage?: string;
  outOfRangeMessage?: string;
  validateNullishValues?: boolean;
}

const validateSingleNumberValue =
  (intl: IntlShape) =>
  (
    valueToValidate: number | undefined,
    {
      max,
      maxMessage,
      min,
      minMessage,
      outOfRangeMessage,
      required,
      requiredMessage,
      validateNullishValues,
    }: ICustomNumberFieldValidatorOptions,
  ) => {
    if (required && (isNil(valueToValidate) || valueToValidate === 0)) {
      return (
        requiredMessage ??
        intl.formatMessage({
          id: "validation.required",
          defaultMessage: "This field is required",
        })
      );
    }

    if (!isNil(valueToValidate) || valueToValidate === 0 || validateNullishValues) {
      if (
        min !== undefined &&
        max !== undefined &&
        (validateNullishValues ||
          ((valueToValidate || valueToValidate === 0) &&
            (valueToValidate < min || valueToValidate > max)))
      ) {
        return (
          outOfRangeMessage ??
          intl.formatMessage(
            {
              id: "validation.numberRange",
              defaultMessage: "Value must be between {min} and {max}",
            },
            { min, max },
          )
        );
      }

      if (
        valueToValidate &&
        min !== undefined &&
        (validateNullishValues ||
          ((valueToValidate || valueToValidate === 0) && valueToValidate < min))
      ) {
        return (
          minMessage ??
          intl.formatMessage(
            {
              id: "validation.min-value",
              defaultMessage: "The value must be at least {min}",
            },
            { min },
          )
        );
      }

      if (
        max !== undefined &&
        (validateNullishValues ||
          ((valueToValidate || valueToValidate === 0) && valueToValidate > max))
      ) {
        return (
          maxMessage ??
          intl.formatMessage(
            {
              id: "validation.max",
              defaultMessage: "The value must be at most {max}",
            },
            { max },
          )
        );
      }
    }

    return undefined;
  };

const validateMultiNumberValue =
  (intl: IntlShape) =>
  (
    valueToValidate: (number | undefined)[] | undefined,
    options: ICustomNumberFieldValidatorOptions,
  ) => {
    const validateSingleValue = validateSingleNumberValue(intl);
    return valueToValidate?.map?.((value) => validateSingleValue(value, options)) ?? [];
  };
const validateNumberValue =
  (intl: IntlShape) =>
  (
    valueToValidate: number | (number | undefined)[] | undefined,
    options: ICustomNumberFieldValidatorOptions,
  ) => {
    if (typeof valueToValidate === "number" || typeof valueToValidate === "undefined") {
      return validateSingleNumberValue(intl)(valueToValidate, options);
    }
    if (Array.isArray(valueToValidate)) {
      return validateMultiNumberValue(intl)(valueToValidate, options);
    }
    return undefined;
  };

export const CustomNumberFieldConsumer = ({
  alwaysShowValidationMessage,
  componentVariant,
  error: parentError,
  float,
  label,
  max,
  maxMessage,
  min,
  minMessage,
  name,
  onChange,
  outOfRangeMessage,
  placeholder,
  required,
  requiredMessage,
  touched: parentTouched,
  value,
}: ICustomNumberFieldConsumerProps) => {
  const intl = useIntl();
  const [touched, setTouched] = useState(parentTouched ?? false);

  const validateValue = useCallback(
    (valueToValidate: number | undefined, validateNullishValues?: boolean) =>
      validateNumberValue(intl)(valueToValidate, {
        min,
        max,
        required,
        minMessage,
        maxMessage,
        requiredMessage,
        outOfRangeMessage,
        validateNullishValues,
      }),
    [intl, min, max, required, minMessage, maxMessage, requiredMessage, outOfRangeMessage],
  );

  const error = useMemo(() => validateValue(value), [validateValue, value]);
  const helperText = useMemo(
    () => validateValue(value, alwaysShowValidationMessage),
    [alwaysShowValidationMessage, validateValue, value],
  );

  if (componentVariant === "lyra") {
    return (
      <NumberField
        hideHintLabel
        name={name}
        label={label}
        isRequired={required}
        placeholder={placeholder}
        value={value === undefined || isNaN(value) ? null : value}
        errorMessage={
          parentError ??
          (touched || parentTouched || alwaysShowValidationMessage
            ? (helperText as string | undefined)
            : undefined)
        }
        hideStepper
        formatOptions={{
          minimumFractionDigits: float ? 2 : 0,
          maximumFractionDigits: float ? 2 : 0,
        }}
        onChange={(newValue) => {
          onChange(
            newValue ?? undefined,
            validateValue(newValue ?? undefined) as string | undefined,
          );
        }}
      />
    );
  }

  return (
    <NumberInput
      name={name}
      label={label}
      required={required}
      placeholder={placeholder}
      value={value === undefined || isNaN(value) ? "" : value}
      precision={float ? 2 : 0}
      thousandSeparator={false}
      error={!!parentError || ((touched || parentTouched) && !!error)}
      onBlur={() => setTouched(true)}
      helperText={
        parentError ??
        (touched || parentTouched || alwaysShowValidationMessage
          ? (helperText as string | undefined)
          : undefined)
      }
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
          onChange(
            undefined,
            touched || parentTouched ? (validateValue(undefined) as string | undefined) : undefined,
          );
        }
        const parsedValue = float ? parseFloat(e.target.value) : parseInt(e.target.value, 10);
        onChange(parsedValue, validateValue(parsedValue) as string | undefined);
      }}
      endIcon={float ? <DecimalIcon fontSize="small" /> : <NumberIcon fontSize="small" />}
    />
  );
};

CustomNumberFieldConsumer.validate = validateNumberValue;
